//
// const headers = new Headers();
// const jwtToken = localStorage.getItem('jwtToken');
// if (jwtToken) {
//   headers.append('Authorization', `Bearer ${jwtToken}`);
// }
//
// const apiUrl = 'http://127.0.0.1:5000';
//
// export default {
//   headers,
//   apiUrl
// };

import axios from 'axios';
// import router from "@/router";

// const apiUrl = 'http://127.0.0.1:5000/api/';
const apiUrl = '/api/';

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json', // 如果后端要求的是 JSON 格式的请求体，可以设置 Content-Type
    // 在此可以添加其他默认请求头部信息
  }
});

axiosInstance.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('jwtToken');

  if (jwtToken) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  }
  return config;
});

// 添加响应拦截器，在接收到响应数据后处理
// axiosInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response.status === 401) { // 如果响应状态码为401
//     // 在这里执行未登录提示并跳转到登录页面的操作
//     console.log("未登录，跳转到登录页面"); // 示例操作，你可以根据你的实际需求修改
//     router.push('/login').then(() => {
//       // 在路由跳转成功后可以执行一些操作，如果不需要的话，这里可以不写任何代码
//     }).catch(err => {
//       console.error("跳转登录页面失败:", err);
//     });
//   }
//   return Promise.reject(error); // 返回一个新的Promise
// });

export default axiosInstance;